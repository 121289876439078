// import axios
import axios from 'axios';

// import lib
import config from '../lib/config';
import { getAuthToken } from '../lib/localStorage'

axios.defaults.headers.common['Authorization'] = getAuthToken();

export const BurnField = async (data) => {
    // //console.lo(data,"dataaaaaaaaaaaaaaaaaaaa")
    try {
          let respData = await axios({
            'method': 'post',
            'url': `${config.vUrl}/api/routesAdmin/BurnField`,
            data: data
        });
        return {
            loading: false,
  
        }
  
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
  }

export const getEmailTemplateList = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getEmailList`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getproductdetails = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/routesAdmin/getproductdetails` ,
            'headers': {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: false,
            result: respData
        }
    }
   
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const getAllartlist = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/routesAdmin/getAllartlist`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const adminburntoken = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/routesAdmin/adminburntoken` ,
            'headers': {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: false,
            result: respData
        }
    }
   
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const removebannerlist = async (data, dispatch) => {
    //console.log('yes');
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/removebannerlist` ,
            'headers': {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: false,
            userValue: respData
        }
    }
   
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const setbannerlist = async (data, dispatch) => {
    //console.log('yes');
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/setbannerlist` ,
            'headers': {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: false,
            userValue: respData
        }
    }
   
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getallrequestlist = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/token/getallrequestlist`,
        });

        return {
            status: "success",
            loading: false,
            result: respData
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getbannerlist = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/token/getbannerlist`,
        });

        return {
            status: "success",
            loading: false,
            result: respData
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getauctionlist = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/getauctionlist`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const getcmslist = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/getcmslist`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

// deletecms




export const deletecms = async (id, dispatch) => {
    //console.log('yes');
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/deletecms/` + id,
            'headers': {
                'Authorization': localStorage.admin_token
            },
        });
        return {
            loading: false,
            userValue: respData
        }
    }
   
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}



export const AddCms = async (data) => {
    try {
        console.log(data,'data  ')
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/addcms`,
            data
        });
        return {
            status: "success",
            loading: true,
            messages: respData.data.messages,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const updatecms = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/updatecms`,
            data
        });
        return {
            status: "success",
            loading: true,
            messages: respData.data.messages,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}





