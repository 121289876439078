import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import config from "../../actions/config.js";
import Web3 from "web3";
import "@metamask/legacy-web3";
import single from "../../ABI/ABI.json";
import multiple from "../../ABI/MULTIABI.json";
import { toast } from "react-toastify";
import { toastAlert } from "../../lib/toastAlert";

//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from "../../lib/isEmpty";

import { addwhitelist, checkwhitelist } from "../../actions/users";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  name: "",
  firstName: "",
  lastName: "",
  email: "",
  phonenumber: "",
  phoneCode: "",
};

const useStyles = makeStyles(styles);

export default function Categoryadd(props) {
  const classes = useStyles();
  const history = useHistory();
  const [toched, setToched] = useState({});

  const dispatch = useDispatch();
  const [userdet, setUser] = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const { userId } = useParams();
  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    console.log(formValue);
  };

  const { address } = formValue;
  const [status, setstatus] = useState(true);

  useEffect(() => {}, []);

  const handlePhoneNumber = (value, country) => {
    const { dialCode } = country;
    let phoneNo = value;
    let formData = formValue;
    if (dialCode) {
      formData = {
        ...formData,
        ...{
          phoneCode: dialCode,
          phoneNo: phoneNo.slice(dialCode.length),
        },
      };
    } else if (value) {
      formData = { ...formData, ...{ phoneNo } };
    }
    setFormValue(formData);
  };
  const handleBlurPhone = (e) => {
    setToched({ ...toched, ...{ phoneNo: true, phoneCode: true } });
  };
  const handleFormSubmit = async (e) => {
    setstatus(false);
    e.preventDefault();
    if (address != "" && address != null && address != undefined) {
      let reqData = {
        address,
      };
      var present = await checkwhitelist(reqData);
      console.log(present, "==================presentpresentpresent");
      if (present && present.result) {
        setstatus(true);
        toastAlert("error", "Address Already Exists", "address");
      } else {
        var networkVersion = config.network;
        if (window.ethereum) {
          try {
            if (typeof web3 !== "undefined") {
              window.ethereum.enable().then(async function () {
                const web3 = new Web3(window.web3.currentProvider);
                if (window.web3.currentProvider.isMetaMask === true) {
                  await web3.eth.getAccounts(async function (error, result) {
                    if (
                      window.web3.currentProvider.networkVersion ==
                      networkVersion
                    ) {
                      var logaddress = result[0];
                      var isAddress = web3.utils.isAddress(logaddress);
                      if (isAddress == false) {
                        toastAlert(
                          "error",
                          "Please enter the valid address",
                          "address"
                        );
                        return false;
                      }
                      const singleContract = new web3.eth.Contract(
                        single,
                        config.single_contract
                      );
                      const multipleContract = new web3.eth.Contract(
                        multiple,
                        config.multiple_contract
                      );
                      console.log(
                        singleContract,
                        "================singleContract"
                      );
                      var owner = await singleContract.methods
                        .getWhitelistAdmin()
                        .call();
                      if (owner == logaddress) {
                        singleContract.methods
                          .addWuser(address)
                          .send({ from: logaddress })
                          .then(async (result) => {
                            multipleContract.methods
                              .addWuser(address)
                              .send({ from: logaddress })
                              .then(async (result1) => {
                                var data = await addwhitelist(reqData);
                                toastAlert(
                                  "success",
                                  "Added Successfully",
                                  "address"
                                );
                                window.location = "/pyramidionsadmin/whitelist";
                                setstatus(true);
                              })
                              .catch((error) => {
                                setstatus(true);
                                toast.error(
                                  "Failed Try Again Later",
                                  toasterOption
                                );
                              });
                          })
                          .catch((error) => {
                            setstatus(true);
                            toast.error(
                              "Failed Try Again Later",
                              toasterOption
                            );
                          });
                      } else {
                        setstatus(true);
                        toastAlert(
                          "error",
                          "Please Login Admin Address",
                          "address"
                        );
                      }
                    } else {
                      setstatus(true);
                      toastAlert(
                        "error",
                        networkVersion +
                          "Please Choose Mainnet" +
                          window.web3.currentProvider.networkVersion,
                        "address"
                      );
                    }
                  });
                } else {
                  setstatus(true);
                  toastAlert(
                    "error",
                    "Please Add Metamask External",
                    "address"
                  );
                }
              });
            } else {
              setstatus(true);
              toastAlert("error", "Please Add Metamask External", "address");
            }
          } catch (err) {
            setstatus(true);
            toast.error("Failed Try Again Later", toasterOption);
          }
        } else {
          setstatus(true);
          toastAlert("error", "Please Add Metamask External", "address");
        }
      }
    } else {
      setstatus(true);
      toastAlert("error", "Please Enter Valid Address", "address");
    }
  };

  let formdata = {};

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Add Whitelist</h4>
                {/* <p className={classes.cardCategoryWhite}>Create a new user</p> */}
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      onChange={onChange}
                      id="address"
                      value={address}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                {status == true ? (
                  <Button color="primary" type="submit">
                    Add
                  </Button>
                ) : (
                  <Button color="primary" type="button">
                    In Progress
                  </Button>
                )}
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
