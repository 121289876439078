let key = {};
if (process.env.REACT_APP_MODE === "production") {
  console.log("Set Production Config");
  const API_URL = "https://pramidionsapi.alwin.io";
  key = {
    baseUrl: `${API_URL}`,
    singlesmartContract: "0x50CF957D65F5B180d8c00e9e10300bEa329aD80C",
    multiplesmartContract: "0xc1d4a69995b3bBc35FDabF04a6c25A8304a8960d",
    network: 4,
  };
} else {
  console.log("Set Production Config");
  const API_URL = "https://pramidionsapi.alwin.io";
  key = {
    baseUrl: `${API_URL}`,
    singlesmartContract: "0x50CF957D65F5B180d8c00e9e10300bEa329aD80C",
    multiplesmartContract: "0xc1d4a69995b3bBc35FDabF04a6c25A8304a8960d",
    network: 4,
  };
}

export default key;
