// @material-ui/icons
import {
  Dashboard,
  Person,
  LibraryBooks,
  List,
  Equalizer,
  Timeline,
  Settings,
  Money,
  History,
  Email,
  Help,
  LiveHelp,
  AccountBalanceWallet,
  AccountBalance,
  ExitToApp,
  ArrowForwardIcon,
} from "@material-ui/icons";

import AdjustIcon from "@material-ui/icons/Adjust";

const dashboardRoutes = [
  {
    id: "login",
    path: "/login",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },
  {
    id: "forgot",
    path: "/forgot",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },
  {
    id: "change-password",
    path: "/changepassword/:authToken",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },
  {
    id: "security",
    path: "/securityType",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },
  {
    id: "edit-profile",
    path: "/editprofile",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },

  /* Dashboard */
  {
    id: "dashboard",
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    isSideMenu: true,
    isEdit: false,
    isRestricted: "single",
  },
  /*Admin  */
  {
    id: "admin",
    path: "/list",
    name: "Admin Management",
    icon: List,
    isSideMenu: true,
    isEdit: true,
    isRestricted: "multi",
    child: [
      {
        id: "add",
        path: "/add",
      },
      {
        id: "edit",
        path: "/edit/:userId",
      },
    ],
  },

  /* Dashboard */
  {
    path: "#",
    id: "dashboard",
    name: "Dashboard",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Dashboard Lists",
        path: "/dashboard",
        icon: Person,
      },
    ],
  },

  /* User */

  {
    path: "#",
    id: "category",
    name: "Category Management",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Category Lists",
        path: "/categorylist",
        icon: Person,
      },
    ],
  },

  {
    path: "#",
    id: "Bid",
    name: "Bid ",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Bid Lists",
        path: "/bidlist",
        icon: Person,
      },
    ],
  },

  {
    path: "#",
    id: "token",
    name: "Token Management",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Token Lists",
        path: "/TokenList",
        icon: Person,
      },
      {
        name: "Report Lists",
        path: "/ReportList",
        icon: Person,
      },
    ],
  },
  // {
  //   path: "#",
  //   id: "nft",
  //   name: "Admin NFT",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Token Lists",
  //       path: "/NFTTokenList",
  //       icon: Person,
  //     },
  //   ],
  // },

  // {
  //   path: "#",
  //   id: "burn",
  //   name: "Burn",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Burn",
  //       path: "/burn",
  //       icon: Person,
  //     },
  //   ],
  // },
  {
    path: "#",
    id: "Whitelist",
    name: "White List ",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "List",
        id: "list",
        path: "/whitelist",
        icon: Person,
      },
      // {
      //   name: "Requst",
      //   id: "request",
      //   path: "/requestwhitelist",
      //   icon: Person,
      // },
    ],
  },
  // {
  //   path: "#",
  //   id: "Profile Verification",
  //   name: "Profile Verification",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Verification Request",
  //       id: "list",
  //       path: "/profileverification",
  //       icon: Person,
  //     },
  //   ],
  // },
  {
    path: "#",
    id: "TFA",
    name: "TFA Authendication",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "TFA",
        id: "list",
        path: "/tfa",
        icon: Person,
      },
    ],
  },
  {
    path: "#",
    id: "collection",
    name: "Banner Management",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Collection Lists",
        path: "/CollectionList",
        icon: Person,
      },
    ],
  },
  {
    path: "#",
    id: "emailTemplate",
    name: "Email Template",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Email Template Lists",
        path: "/emailTemplate",
        icon: Person,
      },
    ],
  },
  {
    path: "#",
    id: "cms",
    name: "CMS Management",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Terms and Condition",
        path: "/termsandcondition",
        icon: Person,
      },
      {
        name: "Privacy",
        path: "/privacy",
        icon: Person,
      },
      {
        name: "About Section",
        path: "/about",
        icon: Person,
      },
    ],
  },

  {
    path: "#",
    id: "settings",
    name: "Settings",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Settings",
        path: "/setting",
        icon: Person,
      },
    ],
  },
  
  {
    path: "#",
    id: "user",
    name: "User-profile Management",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "User-profile Lists",
        path: "/userlist",
        icon: Person,
      },
    ],
  },
];

export default dashboardRoutes;
