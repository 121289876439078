module.exports = {
  baseUrl: "https://pramidionsapi.alwin.io",

  single_contract: "0x50CF957D65F5B180d8c00e9e10300bEa329aD80C",

  multiple_contract: "0xc1d4a69995b3bBc35FDabF04a6c25A8304a8960d",

  ownerAddr: "0x26f9f0c809779fea862b28a106fd883fac4a3fb7",

  network: "4",

  frontUrl: "https://pramidions-nft-frontend.pages.dev/pyramidions/",

  API_URL: "https://pramidionsapi.alwin.io",
};
